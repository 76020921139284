import React, { useState, useEffect, useRef, RefObject, useContext } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Layout.module.css";
import { LoginContext } from "../../loginContext";

import { useLogin, requireLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";
import { IconButton } from "@fluentui/react";

const Layout = () => {
    const { t } = useTranslation();
    const { loggedIn } = useContext(LoginContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef: RefObject<HTMLDivElement> = useRef(null);
    const allowAccess = requireLogin && loggedIn;

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (menuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} ref={menuRef}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img
                            className={styles.headerLogo}
                            src="https://staticfilessagedev.blob.core.windows.net/web/sage-logo-md.png"
                            aria-label={t("headerTitle")}
                        />
                        {loggedIn && <span className={styles.headerTitle}>Varyst AI</span>}
                    </Link>

                    {allowAccess && (
                        <nav>
                            <ul className={`${styles.headerNavList} ${menuOpen ? styles.show : ""}`}>
                                <li>
                                    <NavLink
                                        to="/"
                                        className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                        onClick={() => setMenuOpen(false)}
                                    >
                                        {t("chat")}
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    )}
                    <div className={styles.loginMenuContainer}>
                        {useLogin && <LoginButton />}
                        {allowAccess && (
                            <IconButton
                                iconProps={{ iconName: "GlobalNavButton" }}
                                className={styles.menuToggle}
                                onClick={toggleMenu}
                                ariaLabel={t("labels.toggleMenu")}
                            />
                        )}
                    </div>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
